import React, {Component} from "react"
import Layout from "../templates/basic/"
import SEO from 'components/seo'
import TextInput from 'components/form/textinput/';
import Link from 'components/link';
import { connect } from 'react-redux'

const SEARCH_URL = `${process.env.GATSBY_ZMS_API_URL}search/all/`;

class Search extends Component {
	constructor(props) {
		super(props);
		this.handleSearch = this.handleSearch.bind(this);
		this.setPage = this.setPage.bind(this);
		this.state = {
			terms: props.terms,
			isSearchLoading: false,
			page: 1,
			pageSize: 10,
			count: 0,
			pages: 1,
			results: props.results,
		}
		this.timerID = null;
	}

	doSearch(page = 1) {
		this.setState({
			page: page,
			isSearchLoading: true,
		});
		const terms = this.state.terms;
		if (terms !== '') {
			fetch(`${SEARCH_URL}?terms=${terms}&_page=${page}&_pageSize=${this.state.pageSize}`)
				.then((response) => {
					return response.json();
				})
				.then((json) => {
					this.props.setSearch(terms, json.data||[]);
					this.setState({
						isSearchLoading: false,
						results: json.data||[],
						page: json.page,
						pages: json.pages,
						count: json.total,
					});
				})
				.catch((err) => {
					console.log(err);
				})
			;
		}
		else {
			this.setState({
				isSearchLoading: false,
				results: [],
				page: 1,
				pages: 1,
				count: 0,
			});
		}
	}

	handleSearch(evt) {
		this.setState({
			terms: evt.target.value,
		});
		if (this.timerID !== null)clearTimeout(this.timerID);
		this.timerID = setTimeout(function(){
			this.doSearch();
		}.bind(this), 1000);
	}

	componentDidMount() {
		if (this.props.location.state.terms) {
			this.setState({
				terms: this.props.location.state.terms,
			});
			if (this.timerID !== null)clearTimeout(this.timerID);
			this.timerID = setTimeout(function(){
				this.doSearch();
			}.bind(this), 1000);
		}
	}

	componentWillUnmount() {
		if (this.timerID !== null)clearTimeout(this.timerID);
	}

	setPage(e) {
		this.doSearch(e.target.innerText);
	}

	createPages() {
		let pages = [];
		if (this.state.pages > 1) {
			for (let i=1; i<=this.state.pages; i++) {
				pages.push(<button style={{ display:'inline-block', margin:'0 10px', fontWeight:(i === this.state.page ? 'bold' : '')}} key={i} onClick={this.setPage}>{i}</button>);
			}
		}
		if (pages.length > 0)return (<div>Page: {pages}<br/><br/></div>);
		return (<div/>);
	}

	render() {
		return (
			<div>
			<Layout preTitle="Search" title="Having trouble finding something?" enableFooterTabs={true}>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12 medium-offset-1 medium-10 end">
							<section className="main-content" style={{'marginTop':'100px'}}>
								<p>Just enter some terms in the search bar to find what you're looking for.</p>
								<TextInput autoComplete={false} label="Search Terms" name="terms" value={this.state.terms} onChange={this.handleSearch} />
								{ this.state.terms !== '' && this.state.count > 0 && <div>Showing {this.state.count} results for {this.state.terms}.<br/><br/></div> }
								{ this.state.terms !== '' && this.state.count === 0 && <div>Sorry, we could not find anything with those search terms.</div> }
								{ this.state.isSearchLoading && <div>Please wait while loading results</div> }
								{ this.state.results && this.state.results.map((item, i) => {
									return (
										<div key={item.id}><Link href={item.uri}>
											<strong>{item.title}</strong><br/>
											{item.description}
										</Link><br/><br/></div>
									)
								})}
								{ this.createPages() }
							</section>
						</div>
					</div>
				</div>
			</Layout>
			<SEO title="Search Danos" description="Enter terms in the search bar on this page to find what you're looking for on the Danos website." />
			</div>
		)
	}
}

const mapStateToProps = (state, props) => {
	return {
		terms: state.terms,
		results: state.results,
	};
};
const mapDispatchToProps = (dispatch, props) => {
	return {
		setSearch: (terms, results) => dispatch({
			type: 'SET_SEARCH_RESULTS',
			terms: terms,
			results: results
		}),
	};
};

// export default Search;
export default connect(mapStateToProps, mapDispatchToProps)(Search);